<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <div style="display:flex; flex-direction: column;"
           class="col-lg-12 d-flex align-items-center auth-bg px-2 p-lg-5">
        <div>
          <b-link class="brand-logo">
            <vuexy-logo/>
            <h2 class="brand-text text-primary ml-1">
              InstaPPC
            </h2>
          </b-link>
        </div>
        <div class="mt-5">
          <h3>Terms & Conditions</h3>
        </div>

        <div class="mt-1">
          <h3>ACCOUNT TERMS &amp; WARRANTY</h3>
          <ul>
            <li>All client advertising activities must comply with Google Ads policies: https://support.google.com/adspolicy/answer/6008942?hl=en</li>
            <li>Account suspensions are only covered for payment related issues.</li>
            <li>Payment suspensions are covered up to the first €750 total spend or 1 Month since delivery.</li>
            <li>If applicable, the client is responsible for obtaining Google market certifications. Campaigns launched within 24 hours of receiving Google certification are not covered for any suspension reason.</li>
            <li>Accounts using domains not provided by the company are not covered for any suspension reason.</li>
            <li>The client is responsible for maintaining a sufficient account balance to cover Google charges. If payment is declined due to insufficient balance, the account is not covered for any suspension reason thereafter.</li>
            <li>Accounts that are verified under Google's advertiser verification program will be subject to a higher maintenance fee compared to standard accounts. The specific amount of the fee will be determined based on market constraints and industry standards.</li>
          </ul>
        </div>

        <div style="width:100%;" class="mt-1 mb-1">
          <span>By clicking 'Accept' you are agreeing to our terms and conditions.</span>
          <b-button class="float-right" variant="primary" @click="acceptTermsAndConditions()">
            Accept
          </b-button>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

import {
  BRow, BButton, BLink,
} from 'bootstrap-vue'

export default {
  name: 'termsAndConditions',
  components: {
    BRow,
    BLink,
    BButton,
    VuexyLogo,
  },
  computed: {
    showAcceptButton () {
      return localStorage.getItem('actions')
    },
  },
  methods: {
    acceptTermsAndConditions () {
      this.$store.dispatch('acceptTermsAndConditions', {
        'customData.ppc.termsConfirmed': 'now',
      }).then(resp => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Terms successfully accepted',
            icon: 'CheckIcon',
            variant: 'success',
          },
        }, {
          position: 'top-right',
        })

        localStorage.removeItem('actions')
        this.$router.push({ name: 'home' })
      }).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
